import React from 'react';
import { graphql } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import {
  Link,
  Trans,
  useI18next,
  useTranslation,
} from 'gatsby-plugin-react-i18next';
import { CTACard, ListTLink, animateObjects, newContent } from '../../components/Layout';
import SEO from '../../components/seo';
import GiveContentTrans from '../../components/GiveContentTrans';
import Icon from '../../components/Icon';

import Layout from '../../components/Layout';

/* // Create component in same file. We use it just here so keep it in same file. Otherwise export etc,.
function WorkPreview({ work }) {
  return (
    <div>
      <Link to={`/work/${work.slug}`}>
        <h2>
          <span className="mark">{work.title}</span>
        </h2>
      </Link>
     
      <Img fluid={work.image.asset.fluid} alt={work.title} />
    </div>
  );
} */

// markup
// const IndexPage = ({ data }) => {

function AboutPage() {
  const { t } = useTranslation();
  const { languages, originalPath, i18n } = useI18next();

  const langCode = i18n.language;
  return (
    <Layout>
    <main className="open-service-page outer-grid">
      <GiveContentTrans language="et">
        <SEO
          title="Bännerreklaam mida klikitakse"
          description="Bännerreklaam, mis toob tulemusi! Loome animeeritud HTML5 bännereid Google’i, Facebooki ja LinkedIni jaoks.           "
          image="/pagecover.jpg"
        />
      </GiveContentTrans>
      <GiveContentTrans language="en">
        <SEO
          title="Animated and static Web banners that convert"
          description="Stunning animated HTML5 and static banners! Web banners for Google ad network, Facebook, Instagram, LinkedIn and other social media channels."
          image="/pagecover-en-default.jpg"
        />
      </GiveContentTrans>

      <section className="hero">
        <GiveContentTrans language="et">
          <h1>Veebibannerid</h1>
        </GiveContentTrans>
        <GiveContentTrans language="en">
          <h1>Web Banners</h1>
        </GiveContentTrans>

        <div className="page-copy">
          <GiveContentTrans language="et">
            <p>
            Hästi disainitud animeeritud ja staatilised veebibännerid on digitaalse turunduse olulised relvad, mis haaravad vaatajate tähelepanu ja edastavad sõnumit koheselt ning meeldejäävalt. Aitame teid
              nii staatiliste kui animeeritud HTML5 bänneritega. Soovitame
              strateegiaid, kuidas bännerreklaam teie ettevõtte jaoks
              kasumlikult tööle panna. Pakume välja mida ja kuidas testida ning
              seeläbi parandada tulemusi.
            </p>
          </GiveContentTrans>
          <GiveContentTrans language="en">
            <p>
              Web banner advertising is an effective way to reach your target
              audience. We can assist you with both static and animated HTML5
              banner ads. We offer advice on how to make your banner advertising
              work for your business and provide recommendations on what and how
              to test to improve results.
            </p>
          </GiveContentTrans>
        </div>
      </section>

      <div className="img-100">
        <GiveContentTrans language="et">
          <StaticImage
            src="../../assets/bannerid-1.jpg"
            alt="Veebibannerid"
            layout="fullWidth"
          />
        </GiveContentTrans>
        <GiveContentTrans language="en">
          <StaticImage
            src="../../assets/bannerid-1.jpg"
            alt="Web banners"
            layout="fullWidth"
          />
        </GiveContentTrans>
      </div>

      <section className="description-section inner-grid">
        <GiveContentTrans language="et">
          <h2>Bännerite kujundamine</h2>
        </GiveContentTrans>
        <GiveContentTrans language="en">
          <h2>Web banner design</h2>
        </GiveContentTrans>

        <div className="content-row has-media">
          <div className="content-col-half page-copy">
            <GiveContentTrans language="et">
            <h3>Animeeritud HTML5 ja staatilised reklaam bännerid</h3>
              <p>
              Veebibännerite animeerimine köidab vaatajate pilku. Animeeritud bännerreklaam võimaldab teie brändi sõnumil silma paista tavapäraste staatilise graafika seast. 
              Bännerite animatsioon lisab sügavust ja võimaldab selgemaid sõnumeid esitada lühikese aja jooksul.
                Eelistame animeeritud bännerid kodeerida käsitsi kasutades
                GSAPi. Oma töös oleme kasutanud nii GoogleWebDesigner,
                Bannerflow kui muid alternatiivseid kasutajaliidese põhiseid
                banneri animeerimisplatvorme.
              </p>
              <p>
                Bännereid kujundamine nii Google kui teiste reklaamivõrgustike jaoks. Samamoodi enamlevinud sotsiaalmeedia kanalitesse nagu Facebook, Instagram, X, LinkedIn jne. 
              </p>
            </GiveContentTrans>
            <GiveContentTrans language="en">
            <h3>Animated HTML5 and Static Banners</h3>
      <p>
      Animating web banners captures the viewers' attention, allowing your brand's message to stand out from the usual static graphics. Banner animation adds depth and enables complex ideas to be conveyed in a short amount of time.
      We prefer hand-coding animated banners using GSAP. In our work, we have utilized user interface-based banner animation platforms such as Google Web Designer, Bannerflow, and other alternatives.
      </p>
      <p>
      Designing banners for both Google and other advertising networks, as well as popular social media channels like Facebook, Instagram, X, LinkedIn, etc.
      </p>
            </GiveContentTrans>
          </div>
          <div className="media content-col-half">
            <GiveContentTrans language="et">
              <StaticImage
                src="../../assets/Sotsmeedia-reklaamid.jpg"
                alt="Facebook bännerreklaamid"
                layout="fullWidth"
                title="sotsiaalmeedia bänner"
              />
            </GiveContentTrans>
            <GiveContentTrans language="en">
              <StaticImage
                src="../../assets/Sotsmeedia-reklaamid.jpg"
                alt="Facebook banner ads"
                layout="fullWidth"
                title="social media banner ads"
              />
            </GiveContentTrans>
          </div>
        </div>

        <div className="content-row has-media">
        <div className="media content-col-half">
            <GiveContentTrans language="et">
              <StaticImage
                src="../../assets/LinkedIn-ettevotte-profiil.jpg"
                alt="LinkedIn profiili bänner"
                layout="fullWidth"
                title="bänner"
              />
            </GiveContentTrans>
            <GiveContentTrans language="en">
              <StaticImage
                src="../../assets/LinkedIn-ettevotte-profiil.jpg"
                alt="LinkedIn profile cover photo banner"
                layout="fullWidth"
                title="banner ads"
              />
            </GiveContentTrans>
          </div>
          <div className="content-col-half page-copy">
            <GiveContentTrans language="et">
            <h3>Sotsiaalmeedia bännerite, profiili ja kaanepiltide kujundamine</h3>
              <p>
              Sotsiaalmeedia kaanepilt on sageli esimene asi, mida külastajad või jälgijad näevad, kui nad jõuavad teie profiililehele. Esimene mulje on oluline ning kaanepilt aitab luua visuaalse identiteedi, mis peegeldab teie brändi või isiksust. Hästi kujundatud kaanepilt annab edasi anda teie brändi või isiksuse väärtusi, sõnumit ja visuaalset stiili.
          
              </p>
              <p>
              Hästi kujundatud sotsiaalmeedia kaanepilt oluline tööriist, mis aitab luua positiivset esmamuljet, tugevdada brändi tuntust ja seotust ning jutustada oma lugu. Olgu tegu ettevõtte või persoonibrändiga, võib see visuaalne element mängida olulist rolli teie sotsiaalmeedia strateegias.
              Aitame teie ettevõttele või persoonibrändile kujundada proffessionaalse LinkedIni,Facebooki või mõne muu sotsiaalmeedia kanali kaanepildi. Kui teie ettevõttel puudub unikaalne bränding saame kõigepealt aidata sellega. Loe <ListTLink
              to="/teenused/logo-kujundamine/"
              exit={{
                length: 1,
                zIndex: 2, // exit default is 0
                trigger: ({ exit, e, node }) => animateObjects(exit, node),
              }}
              entry={{
                delay: 0.5,
                length: 0.6,
                trigger: ({ entry, node }) => newContent(node),
              }}
            >
             logo ja brändid kujundamisest siit
            </ListTLink>.
              </p>
            </GiveContentTrans>
            <GiveContentTrans language="en">
            <h3>Designing Social Media Profile Pictures and Cover Images</h3>
      <p>
      A social media cover image is often the first thing visitors or followers see when they land on your profile page. First impressions matter, and the cover image helps create a visual identity that reflects your brand or personality. A well-designed cover image conveys your brand's or personality's values, message, and visual style.
      </p>
      <p>
      A well-designed social media cover image is a crucial tool that helps create a positive first impression, strengthen brand recognition and engagement, and tell your story. Whether it's for a company or personal brand, this visual element can play a significant role in your social media strategy.
      We can assist in designing a professional cover image for your company or personal brand on platforms like LinkedIn, Facebook, or other social media channels. If your company lacks a unique branding, we can help with that first. Learn more about <ListTLink
      to="/services/brand-identity/"
      exit={{
        length: 1,
        zIndex: 2,
        trigger: ({ exit, e, node }) => animateObjects(exit, node),
      }}
      entry={{
        delay: 0.5,
        length: 0.6,
        trigger: ({ entry, node }) => newContent(node),
      }}
    >
     logo and branding design here
    </ListTLink>.
      </p>

            </GiveContentTrans>
          </div>
        </div>

        <GiveContentTrans language="et">
        <section className="description-section inner-grid">

          <div className="section-title">
          <h3>Korduma Kippuvad Küsimused</h3><h2>Bännerreklaami kohta</h2>
          </div>

        <div className="content-row has-media">
        <div id="faq">
  
  <ul>
    <li>
      <input type="checkbox" defaultChecked></input>
      <div className="faq-arrow"><Icon name="arrowleft" /></div>

      <h3>Miks on Give digiagentuuri parim bännerreklaami loomiseks?</h3>
      <div className="answer">
      <p>Me võtame ühtemoodi tõsiselt nii suuri UI/UX projekte kui ka pisemaid bänneri kujundusi. </p>
      <p>Igale bänneri projektile läheneme algusest ja loome unikaalsed loovlahendused. Osad agentuurid ja bännerite disainerid leiavad, et valmiskujunduste põhjade kasutamine 
        on hea võimalus aega kokku hoida ja nii kasumit teenida. Gives vaatame bänneri disaini projekte kui pisikesi sprinte kus tänu suurele kogemusele saame luua kiiresti midagi mis paistab silma ja toob tulemust. 
      </p>
      </div>
    </li>
    <li>
      <input type="checkbox" defaultChecked></input>
      <div className="faq-arrow"><Icon name="arrowleft" /></div>
      <h3>Miks kasutada bännerreklaami?</h3>
      <div className="answer">
      <p>Bännerite kasutamine on üks lihtsamaid viise oma reklaami sõnumi edastamiseks. Nii saate oma pakkumist tutvustada uutele klientidele ja suurendada müüki.</p>
      <p>Bännerid on ideaalsed ja taskukohased brändi tuntuse suurendamiseks. Kui teil pole käimas kampaaniat siis on hea hoida oma ettevõtet pildis. 
        </p>
        <p>Re-marketing kampaaniad. Saate ennast meelde tuletada endistele klientidele või veebikülastajatele kes on teie toote ja teenuse vastu huvi tundnud. Sellised sihitud bännerreklaami kampaaniad on osutunud väga edukaks.
        </p>
  
        </div>
    </li>
    <li>
      <input type="checkbox" defaultChecked></input>
      <div className="faq-arrow"><Icon name="arrowleft" /></div>
      <h3>Mis formaadis bännerid töötavad kõige paremini?</h3>
      <div className="answer">
      
      <p>Google ja muude reklaamivõrgustike kasutamisel soovitame teha kõik kampaania regioonis pakutavad mõõdud. 
        </p>
      <p>
        Kui peame välja tooma kindlad mõõdud siis soovitaksime kindlasti teha 1200x1200 ruudu, 336x280, 300x250 ruudud, 300,600 skyscraperi ja 728x90 horisontaalse banneri. Kuna palju kasutatakse ka mobiilseid seadmeid siis on 320x100 ka heaks mõõduks kuigi sinna on viisakat loovlahendust juba raskem teha.  
         </p>

        </div>
    </li>
    <li>
      <input type="checkbox" defaultChecked></input>
      <div className="faq-arrow"><Icon name="arrowleft" /></div>
      <h3>Kuidas saate bännerreklaami sihtida?</h3>
      <div className="answer">
      <p>Sõltuvalt kasutatavast keskkonnast ja kampaania tüübist on erinevad võimalused.</p>
      <p>Google võrgus saab sihtida näiteks seadme, asukoha, keele, soo, vanuse ja kasutaja huvide järgi. Lisaks saate kontrollida millistes keskkondades teie reklaami näidatakse</p>
      </div>
    </li>

    <li>
      <input type="checkbox" defaultChecked></input>
      <div className="faq-arrow"><Icon name="arrowleft" /></div>
    
      <h3>Kus ma saan ise bännereid kujundada?</h3>
      <div className="answer">
      <p>Võid vaadata Canva programmi. Canva.com</p>
      <p>Kui soovid tõsisemalt veebibännerite kujundamisega tegelema hakata siis fototöötluseks sobib Adobe Photoshop, vektorgraafika loomiseks Adobe Illustraator. Võid kasutada ka teisi programme nagu näiteks Figma, Affinity Designer või Sketch. 
        Gives kodeerime animeeritud bännerreklaami enamasti käsitsi või keerukamate lahenduste puhul kasutame spetsiaalset tarkvara mis aitab antud loovlahendust kõige paremini teostada. 
      </p>


      </div>
    </li>

    <li>
      <input type="checkbox" defaultChecked></input>
      <div className="faq-arrow"><Icon name="arrowleft" /></div>
    
      <h3>Animeeritud vs Staatiline bännerreklaam</h3>
      <div className="answer">
      <p>Igaljuhul kui on võimalik soovitame kasutada animeeritud bännerreklaami.</p>
      <p>TheBannermen andmetel on animeeritud bännerid kuni 4,5 korda effektiivsemad. Animeeritud bännerid tõmbavad paremini pilku ja panevad kasutajat teie bännerisse süvenema. Kui midagi on ümbritsevast sisust erinev siis see jääb alati silma. 
      </p>


      </div>
    </li>

    <li>
      <input type="checkbox" defaultChecked></input>
      <div className="faq-arrow"><Icon name="arrowleft" /></div>
      <h3>Mis programmis te HTML5 bännereid animeerite?</h3>
      <div className="answer">
      <p>Animeeritud bännerid enamikel juhtudel kodeerime käsitsi. Nii saame kõige kiiremad laadimisajad ja optimaalse bännerite mõõdustamis kiiruse.</p>
      <p>Spetsiifiliste kampaaniate ja vajaduste puhul kasutame erinevaid programme mis sobivad konkreetse bännerreklaami kampaania loomiseks kõige paremini. </p>
      </div>
    </li>

    <li>
      <input type="checkbox" defaultChecked></input>
      <div className="faq-arrow"><Icon name="arrowleft" /></div>

      <h3>Kas teil on Google Sertifikaadid?</h3>
      <div className="answer">
      <p>Ei ole.</p>
      <p>Me oleme pigem spetsialiseerunud veebibännerite loovlahenduste kujundamisele ja animeerimisele ning pole Google Sertifikaatide tegemist pidanud vajalikuks.</p>
      </div>
    </li>

    <li>
      <input type="checkbox" defaultChecked></input>
      <div className="faq-arrow"><Icon name="arrowleft" /></div>

      <h3>Kuidas tagate, et loodud bänner sobib minu brändiga?</h3>
      <div className="answer">
      <p>Meie disainer arvestab alati teie olemasoleva brändi visuaaliga ning disainib bännerid sellega sobivaks. Ideaalis saadate te meile ka oma brändiraamatu ja kõikide loodavate kujunduste puhul järgime seda.
</p>
      <p>Kui brändiraamatut veel ei ole siis võibolla oleks mõistlik alustada selle kujundamisest. Lähemalt saate lugeda <ListTLink
              to="/teenused/logo-kujundamine/"
              exit={{
                length: 1,
                zIndex: 2, // exit default is 0
                trigger: ({ exit, e, node }) => animateObjects(exit, node),
              }}
              entry={{
                delay: 0.5,
                length: 0.6,
                trigger: ({ entry, node }) => newContent(node),
              }}
            >
             firmastiili ja logo kujundamise teenuse lehelt
            </ListTLink>.</p>
      </div>
    </li>

    <li>
      <input type="checkbox" defaultChecked></input>
      <div className="faq-arrow"><Icon name="arrowleft" /></div>

      <h3>Kui palju maksab bänner?</h3>
      <div className="answer">
      <p>Bänneri hind algab 100€+km. Iga lisanduv mõõt tuleb soodushinnaga. Täpne hind sõltub visuaalsest lahendusest ja kliendi soovidest.</p>     
      </div>
    </li>
  </ul>
</div>

    </div>

      </section>
      </GiveContentTrans>


        <div className="content-row">
          <GiveContentTrans language="et">
            <CTACard
              to="/teenused/kodulehed/"
              exit={{
                length: 1,
                zIndex: 2, // exit default is 0
                trigger: ({ exit, e, node }) => animateObjects(exit, node),
              }}
              entry={{
                delay: 0.5,
                length: 0.6,
                trigger: ({ entry, node }) => newContent(node),
              }}
            >
              Kodulehekülgede loomine
            </CTACard>
          </GiveContentTrans>
          <GiveContentTrans language="en">
            <CTACard
              to="/services/websites/"
              exit={{
                length: 1,
                zIndex: 2, // exit default is 0
                trigger: ({ exit, e, node }) => animateObjects(exit, node),
              }}
              entry={{
                delay: 0.5,
                length: 0.6,
                trigger: ({ entry, node }) => newContent(node),
              }}
            >
              Website creation
            </CTACard>
          </GiveContentTrans>
        </div>
      </section>
    </main>
  
    </Layout>
  );
}

export default AboutPage;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;